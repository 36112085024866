import React from "react";
import ScrollToTop from "./ScrollToTop";
import HowWeWork from "./HowWeWork";

export default function Hero() {
  return (
    <>
      <div
        className="flex flex-col sm:flex-row min-h-screen border-b-4 border-teal-400"
        style={{ marginTop: "80px" }}
      >
        <ScrollToTop />
        <div className="flex-1 bg-gradient-to-r from-teal-100/50 to-teal-100/50 p-10 flex items-center justify-end">
          <div className="max-w-xl">
            <div className="flex items-center mb-4">
              <div className="border-l-4 border-teal-800 h-12 mr-2"></div>
              <span className="uppercase text-sm text-gray-700">
                Engineering for Passion
              </span>
            </div>
            <h1 className="text-3xl sm:text-5xl font-bold text-teal-800">
              <strong>Eagle Engineering</strong>
            </h1>
            <h2 className="text-2xl sm:text-3xl font-bold text-teal-700 mt-1">
              My Personal Journey!
            </h2>
            <p className="text-md sm:text-xl mt-5 text-teal-700">
              Fueled by curiosity and creativity, I explore{" "}
              <strong>Electrical Engineering Design</strong> as a way to bring
              ideas to life. This is not about work—it's about the joy of
              solving problems and building innovative solutions for fun!
            </p>
          </div>
        </div>

        <div className="flex-1 flex justify-center items-center p-4 bg-gradient-to-b from-teal-100/50 to-white sm:bg-gradient-to-r sm:from-teal-100/50 sm:to-white">
          {/* <img
            src={image1}
            alt="IoT Illustration"
            className="h-80 w-80 sm:mt-20 mb-20 object-cover rounded-2xl"
          /> */}
        </div>
      </div>
      <HowWeWork />
    </>
  );
}
